<template>
    <transition name="fade-in" mode="out-in">
        <AppSpinner v-if="isLoading" />
    </transition>
    <main :class="`p-${pageType} layout--generic`" v-if="data">
        <AppPageHeader
            :title="normalisedData.title || normalisedData.Title"
            :greyScale="is_greyscale"
            :headerImg="getImageUrl(normalisedData?.header_img?.data.attributes) || getImageUrl(normalisedData?.featured_image?.data.attributes)"
            :preTitle="route.params.type === 'posts' && format(new Date(normalisedData.publishedAt), 'dd MMM yyyy')"
        />

        <section
            v-for="{ id, title, content, image, __component, inset } in normalisedData.content_blocks || normalisedData.content_block"
            :class="`content gc gc--${ inset === false ? 'offset' : sectionMap[__component] }`"
            :key="id"
        >
            <figure v-if="image">
                <img
                    :src="image.data?.attributes?.url"
                    :alt="image.data?.attributes?.alternativeText || image.data?.attributes?.name"
                />
            </figure>

            <article>
                <h2 v-if="title">{{ title }}</h2>
                <div v-html="marked(content)" />
            </article>
        </section>
    </main>
</template>

<script setup>
    import { marked } from 'marked'
    import { ref, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { format } from 'date-fns'
    import { useQuery } from '@/composables/useQuery'
    import { getImageUrl } from '@/helpers'

    const route = useRoute()
    const pageType = ref(route.params.type)
    const slug = ref(route.params.slug)

    const endpointType = pageType.value === 'page' ? 'pages' : pageType.value
    const imgType = pageType.value === 'page' ? 'header_img' : 'featured_image'
    const content = pageType.value === 'page' ? 'content_blocks' : 'content_block'
    const requestEndpoint = `/${endpointType}?populate=${imgType}&filters[slug][$eq]=${slug.value}&populate=${content}.image`

    const sectionMap = {
        'general.content-generic': 'intro',
        'general.content-image-left': 'imgLeft',
        'general.content-image-right': 'imgRight',
        'general.full-width-image': 'fullImg',
    }

    const { data, isLoading } = useQuery(pageType.value, requestEndpoint)
    const normalisedData = computed(() => {
        if (!data?.value) return null

        return data.value.data[0].attributes
    })
</script>
